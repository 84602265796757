import React, { useState, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

interface DragAndDropProps {
  htmlContent: string;
  onVerify?: (result: boolean) => void;
}

const DragAndDrop: React.FC<DragAndDropProps> = ({ htmlContent, onVerify }) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, []);

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');

  const dragElements = Array.from(doc.querySelectorAll('.diadibi-drag-element')).map((el) => ({
    id: el.id,
    text: el.textContent?.trim() || '',
  }));

  const dropZones = Array.from(doc.querySelectorAll('.diadibi-drop-zone')).map((el) => ({
    id: el.id,
    label: el.previousElementSibling?.textContent?.trim() || '',
  }));

  const [zones, setZones] = useState<Record<string, string | null>>(
    Object.fromEntries(dropZones.map((zone) => [zone.id, null]))
  );

  const [dragZone, setDragZone] = useState<Record<string, boolean>>(
    Object.fromEntries(dragElements.map((el) => [el.id, true]))
  );

  const [feedback, setFeedback] = useState<Record<string, 'correct' | 'incorrect' | null>>(
    Object.fromEntries(dropZones.map((zone) => [zone.id, null]))
  );

  const moveElementToZone = (itemId: string, zoneId: string) => {
    const previousZoneId = Object.keys(zones).find((key) => zones[key] === itemId);
    if (previousZoneId) {
      setZones((prev) => ({ ...prev, [previousZoneId]: null }));
    }

    const currentElementInZone = zones[zoneId];
    if (currentElementInZone) {
      setDragZone((prev) => ({ ...prev, [currentElementInZone]: true }));
    }

    setZones((prev) => ({ ...prev, [zoneId]: itemId }));
    setDragZone((prev) => ({ ...prev, [itemId]: false }));
    setFeedback((prev) => ({ ...prev, [zoneId]: null }));
  };

  const returnToDragZone = (itemId: string) => {
    const previousZoneId = Object.keys(zones).find((key) => zones[key] === itemId);
    if (previousZoneId) {
      setZones((prev) => ({ ...prev, [previousZoneId]: null }));
    }
    setDragZone((prev) => ({ ...prev, [itemId]: true }));
  };

  const DragElement = ({ id, text }: { id: string; text: string }) => {
    const [{ isDragging }, drag] = useDrag({
      type: 'ELEMENT',
      item: { id },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    return (
      <div
        ref={drag}
        style={{
          opacity: isDragging ? 0.5 : 1,
          padding: '0.75rem 1rem',
          margin: '0.25rem',
          backgroundColor: theme.palette.grey[100],
          color: theme.palette.text.primary,
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          cursor: 'grab',
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '0.9rem',
        }}
        onDoubleClick={() => returnToDragZone(id)}
      >
        {text}
      </div>
    );
  };

  const DropZone = ({ id, label }: { id: string; label: string }) => {
    const [{ isOver }, drop] = useDrop({
      accept: 'ELEMENT',
      drop: (item: { id: string }) => moveElementToZone(item.id, id),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    });

    const borderColor =
      feedback[id] === 'correct'
        ? theme.palette.success.main
        : feedback[id] === 'incorrect'
        ? theme.palette.error.main
        : theme.palette.grey[400];

    return (
      <div style={{ marginBottom: '0.75rem' }}>
        <h4
          style={{
            fontWeight: 'bold',
            color: theme.palette.text.primary,
            marginBottom: '0.25rem',
            fontSize: '0.9rem',
          }}
        >
          {label}
        </h4>
        <div
          ref={drop}
          style={{
            padding: '0',
            border: `4px solid ${borderColor}`,
            borderRadius: '0.5rem',
            textAlign: 'center',
            minHeight: '50px',
            minWidth: '400px',
            backgroundColor: theme.palette.background.paper,
            boxShadow: isOver ? `0px 0px 8px ${theme.palette.primary.main}` : undefined,
          }}
        >
          {zones[id] && (
            <DragElement
              id={zones[id]!}
              text={dragElements.find((el) => el.id === zones[id])?.text || ''}
            />
          )}
        </div>
      </div>
    );
  };

  const handleVerify = () => {
    const newFeedback = { ...feedback };
    let allCorrect = true;

    dropZones.forEach((zone) => {
      if (zones[zone.id] === zone.id) {
        newFeedback[zone.id] = 'correct';
      } else {
        if (zones[zone.id]) {
          returnToDragZone(zones[zone.id]!);
        }
        newFeedback[zone.id] = 'incorrect';
        allCorrect = false;
      }
    });

    setFeedback(newFeedback);
    if (onVerify) onVerify(allCorrect);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        ref={containerRef}
        style={{
          padding: '2rem',
          borderRadius: '1rem',
          backgroundColor: theme.palette.background.paper,
          boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
          maxWidth: `${containerWidth || 600}px`,
        }}
      >
        {doc.body.firstChild && (
          <h3 style={{ color: 'black', marginBottom: '1rem', fontWeight: 'bold', fontSize: '1.1rem' }}>
            {doc.body.firstChild.textContent?.trim()}
          </h3>
        )}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.9rem' }}>
          {dropZones.map(({ id, label }) => (
            <DropZone key={id} id={id} label={label} />
          ))}
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem', marginTop: '1rem' }}>
          {dragElements
            .filter(({ id }) => dragZone[id])
            .map(({ id, text }) => (
              <DragElement key={id} id={id} text={text} />
            ))}
        </div>
        <button
          onClick={handleVerify}
          style={{
            marginTop: '1rem',
            padding: '0.5rem 1rem',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            border: 'none',
            borderRadius: '0.5rem',
            cursor: 'pointer',
          }}
        >
          Überprüfen
        </button>
      </div>
    </DndProvider>
  );
};

export default DragAndDrop;
